exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-policy-mdx": () => import("./../../../src/pages/privacy-policy.mdx" /* webpackChunkName: "component---src-pages-privacy-policy-mdx" */),
  "component---src-pages-security-mdx": () => import("./../../../src/pages/security.mdx" /* webpackChunkName: "component---src-pages-security-mdx" */),
  "component---src-pages-solutions-jira-async-poker-faq-tsx": () => import("./../../../src/pages/solutions/jira/async-poker/faq.tsx" /* webpackChunkName: "component---src-pages-solutions-jira-async-poker-faq-tsx" */),
  "component---src-pages-solutions-jira-async-poker-index-tsx": () => import("./../../../src/pages/solutions/jira/async-poker/index.tsx" /* webpackChunkName: "component---src-pages-solutions-jira-async-poker-index-tsx" */),
  "component---src-pages-solutions-monday-async-poker-index-tsx": () => import("./../../../src/pages/solutions/monday/async-poker/index.tsx" /* webpackChunkName: "component---src-pages-solutions-monday-async-poker-index-tsx" */),
  "component---src-pages-solutions-monday-sync-poker-index-tsx": () => import("./../../../src/pages/solutions/monday/sync-poker/index.tsx" /* webpackChunkName: "component---src-pages-solutions-monday-sync-poker-index-tsx" */),
  "component---src-pages-solutions-tsx": () => import("./../../../src/pages/solutions.tsx" /* webpackChunkName: "component---src-pages-solutions-tsx" */),
  "component---src-pages-support-tsx": () => import("./../../../src/pages/support.tsx" /* webpackChunkName: "component---src-pages-support-tsx" */),
  "component---src-pages-terms-mdx": () => import("./../../../src/pages/terms.mdx" /* webpackChunkName: "component---src-pages-terms-mdx" */),
  "component---src-templates-blog-list-template-tsx": () => import("./../../../src/templates/blog-list-template.tsx" /* webpackChunkName: "component---src-templates-blog-list-template-tsx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-10-planning-poker-tips-and-tricks-index-mdx": () => import("./../../../src/templates/blog-post-template.tsx?__contentFilePath=/opt/buildhome/repo/src/content/posts/10-planning-poker-tips-and-tricks/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-10-planning-poker-tips-and-tricks-index-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-11-benefits-of-scrum-and-how-to-achieve-them-index-mdx": () => import("./../../../src/templates/blog-post-template.tsx?__contentFilePath=/opt/buildhome/repo/src/content/posts/11-benefits-of-scrum-and-how-to-achieve-them/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-11-benefits-of-scrum-and-how-to-achieve-them-index-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-11-myths-about-scrum-agile-methodology-index-mdx": () => import("./../../../src/templates/blog-post-template.tsx?__contentFilePath=/opt/buildhome/repo/src/content/posts/11-myths-about-scrum-agile-methodology/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-11-myths-about-scrum-agile-methodology-index-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-12-main-changes-in-scrum-latest-guide-2020-index-mdx": () => import("./../../../src/templates/blog-post-template.tsx?__contentFilePath=/opt/buildhome/repo/src/content/posts/12-main-changes-in-scrum-latest-guide-2020/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-12-main-changes-in-scrum-latest-guide-2020-index-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-13-best-books-for-scrum-masters-index-mdx": () => import("./../../../src/templates/blog-post-template.tsx?__contentFilePath=/opt/buildhome/repo/src/content/posts/13-best-books-for-scrum-masters/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-13-best-books-for-scrum-masters-index-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-2023-how-to-estimate-product-backlog-items-in-scrum-index-mdx": () => import("./../../../src/templates/blog-post-template.tsx?__contentFilePath=/opt/buildhome/repo/src/content/posts/2023-how-to-estimate-product-backlog-items-in-scrum/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-2023-how-to-estimate-product-backlog-items-in-scrum-index-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-3-problems-scrum-fails-to-resolve-index-mdx": () => import("./../../../src/templates/blog-post-template.tsx?__contentFilePath=/opt/buildhome/repo/src/content/posts/3-problems-scrum-fails-to-resolve/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-3-problems-scrum-fails-to-resolve-index-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-3-roles-to-be-present-in-scrum-estimation-of-product-backlog-index-mdx": () => import("./../../../src/templates/blog-post-template.tsx?__contentFilePath=/opt/buildhome/repo/src/content/posts/3-roles-to-be-present-in-scrum-estimation-of-product-backlog/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-3-roles-to-be-present-in-scrum-estimation-of-product-backlog-index-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-5-alternative-agile-methodologies-other-than-scrum-index-mdx": () => import("./../../../src/templates/blog-post-template.tsx?__contentFilePath=/opt/buildhome/repo/src/content/posts/5-alternative-agile-methodologies-other-than-scrum/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-5-alternative-agile-methodologies-other-than-scrum-index-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-5-alternatives-of-planning-poker-estimation-technique-index-mdx": () => import("./../../../src/templates/blog-post-template.tsx?__contentFilePath=/opt/buildhome/repo/src/content/posts/5-alternatives-of-planning-poker-estimation-technique/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-5-alternatives-of-planning-poker-estimation-technique-index-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-7-common-sprint-planning-mistakes-you-should-avoid-index-mdx": () => import("./../../../src/templates/blog-post-template.tsx?__contentFilePath=/opt/buildhome/repo/src/content/posts/7-common-sprint-planning-mistakes-you-should-avoid/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-7-common-sprint-planning-mistakes-you-should-avoid-index-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-7-signs-your-product-backlog-is-a-mess-index-mdx": () => import("./../../../src/templates/blog-post-template.tsx?__contentFilePath=/opt/buildhome/repo/src/content/posts/7-signs-your-product-backlog-is-a-mess/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-7-signs-your-product-backlog-is-a-mess-index-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-7-tips-to-better-prioritize-product-backlog-items-in-scrum-index-mdx": () => import("./../../../src/templates/blog-post-template.tsx?__contentFilePath=/opt/buildhome/repo/src/content/posts/7-tips-to-better-prioritize-product-backlog-items-in-scrum/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-7-tips-to-better-prioritize-product-backlog-items-in-scrum-index-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-8-agile-estimation-mistakes-to-avoid-index-mdx": () => import("./../../../src/templates/blog-post-template.tsx?__contentFilePath=/opt/buildhome/repo/src/content/posts/8-agile-estimation-mistakes-to-avoid/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-8-agile-estimation-mistakes-to-avoid-index-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-8-reasons-to-use-scrum-over-waterfall-index-mdx": () => import("./../../../src/templates/blog-post-template.tsx?__contentFilePath=/opt/buildhome/repo/src/content/posts/8-reasons-to-use-scrum-over-waterfall/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-8-reasons-to-use-scrum-over-waterfall-index-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-8-secrets-to-improve-scrum-team-productivity-index-mdx": () => import("./../../../src/templates/blog-post-template.tsx?__contentFilePath=/opt/buildhome/repo/src/content/posts/8-secrets-to-improve-scrum-team-productivity/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-8-secrets-to-improve-scrum-team-productivity-index-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-8-tips-for-new-scrum-masters-index-mdx": () => import("./../../../src/templates/blog-post-template.tsx?__contentFilePath=/opt/buildhome/repo/src/content/posts/8-tips-for-new-scrum-masters/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-8-tips-for-new-scrum-masters-index-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-8-tips-new-scrum-teams-must-know-before-proceeding-index-mdx": () => import("./../../../src/templates/blog-post-template.tsx?__contentFilePath=/opt/buildhome/repo/src/content/posts/8-tips-new-scrum-teams-must-know-before-proceeding/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-8-tips-new-scrum-teams-must-know-before-proceeding-index-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-8-tips-to-do-accurate-estimates-of-product-backlog-in-agile-index-mdx": () => import("./../../../src/templates/blog-post-template.tsx?__contentFilePath=/opt/buildhome/repo/src/content/posts/8-tips-to-do-accurate-estimates-of-product-backlog-in-agile/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-8-tips-to-do-accurate-estimates-of-product-backlog-in-agile-index-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-8-tips-to-improve-cross-functionality-within-your-scrum-team-index-mdx": () => import("./../../../src/templates/blog-post-template.tsx?__contentFilePath=/opt/buildhome/repo/src/content/posts/8-tips-to-improve-cross-functionality-within-your-scrum-team/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-8-tips-to-improve-cross-functionality-within-your-scrum-team-index-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-8-tips-to-improve-your-product-backlog-estimation-posture-in-2023-index-mdx": () => import("./../../../src/templates/blog-post-template.tsx?__contentFilePath=/opt/buildhome/repo/src/content/posts/8-tips-to-improve-your-product-backlog-estimation-posture-in-2023/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-8-tips-to-improve-your-product-backlog-estimation-posture-in-2023-index-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-9-common-mistakes-with-planning-poker-their-remedies-index-mdx": () => import("./../../../src/templates/blog-post-template.tsx?__contentFilePath=/opt/buildhome/repo/src/content/posts/9-common-mistakes-with-planning-poker-their-remedies/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-9-common-mistakes-with-planning-poker-their-remedies-index-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-9-steps-to-run-agile-scrum-daily-standup-meetings-efficiently-index-mdx": () => import("./../../../src/templates/blog-post-template.tsx?__contentFilePath=/opt/buildhome/repo/src/content/posts/9-steps-to-run-agile-scrum-daily-standup-meetings-efficiently/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-9-steps-to-run-agile-scrum-daily-standup-meetings-efficiently-index-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-9-tips-for-new-teams-to-optimize-backlog-estimation-index-mdx": () => import("./../../../src/templates/blog-post-template.tsx?__contentFilePath=/opt/buildhome/repo/src/content/posts/9-tips-for-new-teams-to-optimize-backlog-estimation/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-9-tips-for-new-teams-to-optimize-backlog-estimation-index-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-a-typical-sprint-from-start-to-its-completion-index-mdx": () => import("./../../../src/templates/blog-post-template.tsx?__contentFilePath=/opt/buildhome/repo/src/content/posts/a-typical-sprint-from-start-to-its-completion/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-a-typical-sprint-from-start-to-its-completion-index-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-affinity-estimation-vs-planning-poker-index-mdx": () => import("./../../../src/templates/blog-post-template.tsx?__contentFilePath=/opt/buildhome/repo/src/content/posts/affinity-estimation-vs-planning-poker/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-affinity-estimation-vs-planning-poker-index-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-affinity-estimation-what-how-and-why-index-mdx": () => import("./../../../src/templates/blog-post-template.tsx?__contentFilePath=/opt/buildhome/repo/src/content/posts/affinity-estimation-what-how-and-why/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-affinity-estimation-what-how-and-why-index-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-agile-estimation-and-the-ten-best-estimation-techniques-index-mdx": () => import("./../../../src/templates/blog-post-template.tsx?__contentFilePath=/opt/buildhome/repo/src/content/posts/agile-estimation-and-the-ten-best-estimation-techniques/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-agile-estimation-and-the-ten-best-estimation-techniques-index-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-agile-scrum-framework-structure-role-and-responsibilities-index-mdx": () => import("./../../../src/templates/blog-post-template.tsx?__contentFilePath=/opt/buildhome/repo/src/content/posts/agile-scrum-framework-structure-role-and-responsibilities/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-agile-scrum-framework-structure-role-and-responsibilities-index-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-agile-scrum-methodology-impact-on-project-management-index-mdx": () => import("./../../../src/templates/blog-post-template.tsx?__contentFilePath=/opt/buildhome/repo/src/content/posts/agile-scrum-methodology-impact-on-project-management/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-agile-scrum-methodology-impact-on-project-management-index-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-agile-trends-to-watch-in-2022-index-mdx": () => import("./../../../src/templates/blog-post-template.tsx?__contentFilePath=/opt/buildhome/repo/src/content/posts/agile-trends-to-watch-in-2022/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-agile-trends-to-watch-in-2022-index-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-are-group-estimates-more-accurate-index-mdx": () => import("./../../../src/templates/blog-post-template.tsx?__contentFilePath=/opt/buildhome/repo/src/content/posts/are-group-estimates-more-accurate/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-are-group-estimates-more-accurate-index-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-article-best-practices-for-estimating-product-backlog-efficiently-index-mdx": () => import("./../../../src/templates/blog-post-template.tsx?__contentFilePath=/opt/buildhome/repo/src/content/posts/article-best-practices-for-estimating-product-backlog-efficiently/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-article-best-practices-for-estimating-product-backlog-efficiently-index-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-async-poker-estimation-can-help-remote-or-distributed-scrum-teams-index-mdx": () => import("./../../../src/templates/blog-post-template.tsx?__contentFilePath=/opt/buildhome/repo/src/content/posts/async-poker-estimation-can-help-remote-or-distributed-scrum-teams/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-async-poker-estimation-can-help-remote-or-distributed-scrum-teams-index-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-async-poker-for-jira-new-slack-integration-index-mdx": () => import("./../../../src/templates/blog-post-template.tsx?__contentFilePath=/opt/buildhome/repo/src/content/posts/async-poker-for-jira-new-slack-integration/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-async-poker-for-jira-new-slack-integration-index-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-async-poker-vs-planning-poker-index-mdx": () => import("./../../../src/templates/blog-post-template.tsx?__contentFilePath=/opt/buildhome/repo/src/content/posts/async-poker-vs-planning-poker/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-async-poker-vs-planning-poker-index-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-asynchronous-estimation-in-jira-the-right-way-index-mdx": () => import("./../../../src/templates/blog-post-template.tsx?__contentFilePath=/opt/buildhome/repo/src/content/posts/asynchronous-estimation-in-jira-the-right-way/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-asynchronous-estimation-in-jira-the-right-way-index-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-beginners-guide-to-running-your-first-planning-poker-session-index-mdx": () => import("./../../../src/templates/blog-post-template.tsx?__contentFilePath=/opt/buildhome/repo/src/content/posts/beginners-guide-to-running-your-first-planning-poker-session/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-beginners-guide-to-running-your-first-planning-poker-session-index-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-best-practices-for-effective-sprint-retrospective-index-mdx": () => import("./../../../src/templates/blog-post-template.tsx?__contentFilePath=/opt/buildhome/repo/src/content/posts/best-practices-for-effective-sprint-retrospective/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-best-practices-for-effective-sprint-retrospective-index-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-best-practices-for-productive-sprint-review-meeting-index-mdx": () => import("./../../../src/templates/blog-post-template.tsx?__contentFilePath=/opt/buildhome/repo/src/content/posts/best-practices-for-productive-sprint-review-meeting/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-best-practices-for-productive-sprint-review-meeting-index-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-common-challenges-faced-by-scrum-teams-index-mdx": () => import("./../../../src/templates/blog-post-template.tsx?__contentFilePath=/opt/buildhome/repo/src/content/posts/common-challenges-faced-by-scrum-teams/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-common-challenges-faced-by-scrum-teams-index-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-continuous-improvement-in-scrum-agile-index-mdx": () => import("./../../../src/templates/blog-post-template.tsx?__contentFilePath=/opt/buildhome/repo/src/content/posts/continuous-improvement-in-scrum-agile/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-continuous-improvement-in-scrum-agile-index-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-definition-of-done-in-scrum-index-mdx": () => import("./../../../src/templates/blog-post-template.tsx?__contentFilePath=/opt/buildhome/repo/src/content/posts/definition-of-done-in-scrum/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-definition-of-done-in-scrum-index-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-dos-and-donts-of-a-scrum-master-index-mdx": () => import("./../../../src/templates/blog-post-template.tsx?__contentFilePath=/opt/buildhome/repo/src/content/posts/dos-and-donts-of-a-scrum-master/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-dos-and-donts-of-a-scrum-master-index-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-dos-and-donts-of-scrum-backlog-estimation-index-mdx": () => import("./../../../src/templates/blog-post-template.tsx?__contentFilePath=/opt/buildhome/repo/src/content/posts/dos-and-donts-of-scrum-backlog-estimation/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-dos-and-donts-of-scrum-backlog-estimation-index-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-estimates-from-the-viewpoint-of-scrum-index-mdx": () => import("./../../../src/templates/blog-post-template.tsx?__contentFilePath=/opt/buildhome/repo/src/content/posts/estimates-from-the-viewpoint-of-scrum/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-estimates-from-the-viewpoint-of-scrum-index-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-everything-you-need-to-know-about-dot-voting-estimation-technique-index-mdx": () => import("./../../../src/templates/blog-post-template.tsx?__contentFilePath=/opt/buildhome/repo/src/content/posts/everything-you-need-to-know-about-dot-voting-estimation-technique/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-everything-you-need-to-know-about-dot-voting-estimation-technique-index-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-evolution-of-planning-poker-iinto-async-poker-and-hybrid-poker-index-mdx": () => import("./../../../src/templates/blog-post-template.tsx?__contentFilePath=/opt/buildhome/repo/src/content/posts/evolution-of-planning-poker-iinto-async-poker-and-hybrid-poker/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-evolution-of-planning-poker-iinto-async-poker-and-hybrid-poker-index-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-how-does-planning-poker-encourage-team-collaboration-index-mdx": () => import("./../../../src/templates/blog-post-template.tsx?__contentFilePath=/opt/buildhome/repo/src/content/posts/how-does-planning-poker-encourage-team-collaboration/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-how-does-planning-poker-encourage-team-collaboration-index-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-how-effective-is-remote-product-backlog-estimation-in-scrum-index-mdx": () => import("./../../../src/templates/blog-post-template.tsx?__contentFilePath=/opt/buildhome/repo/src/content/posts/how-effective-is-remote-product-backlog-estimation-in-scrum/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-how-effective-is-remote-product-backlog-estimation-in-scrum-index-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-how-long-does-planning-poker-session-take-to-complete-index-mdx": () => import("./../../../src/templates/blog-post-template.tsx?__contentFilePath=/opt/buildhome/repo/src/content/posts/how-long-does-planning-poker-session-take-to-complete/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-how-long-does-planning-poker-session-take-to-complete-index-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-how-product-owner-and-scrum-master-can-contribute-to-effective-product-backlog-management-index-mdx": () => import("./../../../src/templates/blog-post-template.tsx?__contentFilePath=/opt/buildhome/repo/src/content/posts/how-product-owner-and-scrum-master-can-contribute-to-effective-product-backlog-management/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-how-product-owner-and-scrum-master-can-contribute-to-effective-product-backlog-management-index-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-how-to-conduct-backlog-refinement-meetings-effectively-index-mdx": () => import("./../../../src/templates/blog-post-template.tsx?__contentFilePath=/opt/buildhome/repo/src/content/posts/how-to-conduct-backlog-refinement-meetings-effectively/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-how-to-conduct-backlog-refinement-meetings-effectively-index-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-how-to-estimate-story-points-in-the-simplest-and-easiest-way-index-mdx": () => import("./../../../src/templates/blog-post-template.tsx?__contentFilePath=/opt/buildhome/repo/src/content/posts/how-to-estimate-story-points-in-the-simplest-and-easiest-way/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-how-to-estimate-story-points-in-the-simplest-and-easiest-way-index-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-how-to-execute-planning-poker-rightly-with-the-correct-order-of-steps-index-mdx": () => import("./../../../src/templates/blog-post-template.tsx?__contentFilePath=/opt/buildhome/repo/src/content/posts/how-to-execute-planning-poker-rightly-with-the-correct-order-of-steps/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-how-to-execute-planning-poker-rightly-with-the-correct-order-of-steps-index-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-how-to-handle-technical-debt-in-scrum-index-mdx": () => import("./../../../src/templates/blog-post-template.tsx?__contentFilePath=/opt/buildhome/repo/src/content/posts/how-to-handle-technical-debt-in-scrum/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-how-to-handle-technical-debt-in-scrum-index-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-how-to-improve-the-team-velocity-in-scrum-index-mdx": () => import("./../../../src/templates/blog-post-template.tsx?__contentFilePath=/opt/buildhome/repo/src/content/posts/how-to-improve-the-team-velocity-in-scrum/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-how-to-improve-the-team-velocity-in-scrum-index-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-how-to-know-which-scrum-estimation-technique-is-best-for-you-index-mdx": () => import("./../../../src/templates/blog-post-template.tsx?__contentFilePath=/opt/buildhome/repo/src/content/posts/how-to-know-which-scrum-estimation-technique-is-best-for-you/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-how-to-know-which-scrum-estimation-technique-is-best-for-you-index-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-how-to-make-more-accurate-estimates-with-planning-poker-index-mdx": () => import("./../../../src/templates/blog-post-template.tsx?__contentFilePath=/opt/buildhome/repo/src/content/posts/how-to-make-more-accurate-estimates-with-planning-poker/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-how-to-make-more-accurate-estimates-with-planning-poker-index-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-how-to-make-remote-scrum-master-role-work-index-mdx": () => import("./../../../src/templates/blog-post-template.tsx?__contentFilePath=/opt/buildhome/repo/src/content/posts/how-to-make-remote-scrum-master-role-work/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-how-to-make-remote-scrum-master-role-work-index-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-how-to-manage-a-remote-scrum-team-index-mdx": () => import("./../../../src/templates/blog-post-template.tsx?__contentFilePath=/opt/buildhome/repo/src/content/posts/how-to-manage-a-remote-scrum-team/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-how-to-manage-a-remote-scrum-team-index-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-how-to-measure-success-in-scrum-agile-methodology-index-mdx": () => import("./../../../src/templates/blog-post-template.tsx?__contentFilePath=/opt/buildhome/repo/src/content/posts/how-to-measure-success-in-scrum-agile-methodology/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-how-to-measure-success-in-scrum-agile-methodology-index-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-how-to-scale-scrum-index-mdx": () => import("./../../../src/templates/blog-post-template.tsx?__contentFilePath=/opt/buildhome/repo/src/content/posts/how-to-scale-scrum/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-how-to-scale-scrum-index-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-is-planning-poker-right-for-your-agile-team-index-mdx": () => import("./../../../src/templates/blog-post-template.tsx?__contentFilePath=/opt/buildhome/repo/src/content/posts/is-planning-poker-right-for-your-agile-team/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-is-planning-poker-right-for-your-agile-team-index-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-key-benefits-of-asynchronous-planning-poker-index-mdx": () => import("./../../../src/templates/blog-post-template.tsx?__contentFilePath=/opt/buildhome/repo/src/content/posts/key-benefits-of-asynchronous-planning-poker/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-key-benefits-of-asynchronous-planning-poker-index-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-key-characteristics-of-planning-poker-estimation-technique-index-mdx": () => import("./../../../src/templates/blog-post-template.tsx?__contentFilePath=/opt/buildhome/repo/src/content/posts/key-characteristics-of-planning-poker-estimation-technique/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-key-characteristics-of-planning-poker-estimation-technique-index-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-main-differences-between-agile-and-scrum-index-mdx": () => import("./../../../src/templates/blog-post-template.tsx?__contentFilePath=/opt/buildhome/repo/src/content/posts/main-differences-between-agile-and-scrum/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-main-differences-between-agile-and-scrum-index-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-planning-poker-101-index-mdx": () => import("./../../../src/templates/blog-post-template.tsx?__contentFilePath=/opt/buildhome/repo/src/content/posts/planning-poker-101/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-planning-poker-101-index-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-planning-poker-vs-t-shirt-sizing-vs-dot-voting-key-differences-index-mdx": () => import("./../../../src/templates/blog-post-template.tsx?__contentFilePath=/opt/buildhome/repo/src/content/posts/planning-poker-vs-t-shirt-sizing-vs-dot-voting-key-differences/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-planning-poker-vs-t-shirt-sizing-vs-dot-voting-key-differences-index-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-product-and-sprint-backlogs-should-be-estimated-as-two-different-units-index-mdx": () => import("./../../../src/templates/blog-post-template.tsx?__contentFilePath=/opt/buildhome/repo/src/content/posts/product-and-sprint-backlogs-should-be-estimated-as-two-different-units/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-product-and-sprint-backlogs-should-be-estimated-as-two-different-units-index-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-product-backlog-estimation-in-2024-index-mdx": () => import("./../../../src/templates/blog-post-template.tsx?__contentFilePath=/opt/buildhome/repo/src/content/posts/product-backlog-estimation-in-2024/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-product-backlog-estimation-in-2024-index-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-product-backlog-vs-sprint-backlog-key-difference-index-mdx": () => import("./../../../src/templates/blog-post-template.tsx?__contentFilePath=/opt/buildhome/repo/src/content/posts/product-backlog-vs-sprint-backlog-key-difference/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-product-backlog-vs-sprint-backlog-key-difference-index-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-relative-estimation-vs-absolute-estimation-index-mdx": () => import("./../../../src/templates/blog-post-template.tsx?__contentFilePath=/opt/buildhome/repo/src/content/posts/relative-estimation-vs-absolute-estimation/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-relative-estimation-vs-absolute-estimation-index-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-scrum-master-vs-project-manager-index-mdx": () => import("./../../../src/templates/blog-post-template.tsx?__contentFilePath=/opt/buildhome/repo/src/content/posts/scrum-master-vs-project-manager/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-scrum-master-vs-project-manager-index-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-scrum-vs-kanban-key-differences-index-mdx": () => import("./../../../src/templates/blog-post-template.tsx?__contentFilePath=/opt/buildhome/repo/src/content/posts/scrum-vs-kanban-key-differences/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-scrum-vs-kanban-key-differences-index-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-self-organizing-teams-in-scrum-index-mdx": () => import("./../../../src/templates/blog-post-template.tsx?__contentFilePath=/opt/buildhome/repo/src/content/posts/self-organizing-teams-in-scrum/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-self-organizing-teams-in-scrum-index-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-should-scrum-teams-estimate-product-backlog-remotely-in-2023-index-mdx": () => import("./../../../src/templates/blog-post-template.tsx?__contentFilePath=/opt/buildhome/repo/src/content/posts/should-scrum-teams-estimate-product-backlog-remotely-in-2023/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-should-scrum-teams-estimate-product-backlog-remotely-in-2023-index-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-sprint-review-vs-sprint-retrospective-key-differences-index-mdx": () => import("./../../../src/templates/blog-post-template.tsx?__contentFilePath=/opt/buildhome/repo/src/content/posts/sprint-review-vs-sprint-retrospective-key-differences/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-sprint-review-vs-sprint-retrospective-key-differences-index-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-story-points-vs-hours-index-mdx": () => import("./../../../src/templates/blog-post-template.tsx?__contentFilePath=/opt/buildhome/repo/src/content/posts/story-points-vs-hours/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-story-points-vs-hours-index-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-team-cost-estimation-with-planning-poker-index-mdx": () => import("./../../../src/templates/blog-post-template.tsx?__contentFilePath=/opt/buildhome/repo/src/content/posts/team-cost-estimation-with-planning-poker/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-team-cost-estimation-with-planning-poker-index-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-the-7-must-do-events-in-scrum-and-their-purpose-index-mdx": () => import("./../../../src/templates/blog-post-template.tsx?__contentFilePath=/opt/buildhome/repo/src/content/posts/the-7-must-do-events-in-scrum-and-their-purpose/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-the-7-must-do-events-in-scrum-and-their-purpose-index-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-the-bucket-system-estimation-technique-index-mdx": () => import("./../../../src/templates/blog-post-template.tsx?__contentFilePath=/opt/buildhome/repo/src/content/posts/the-bucket-system-estimation-technique/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-the-bucket-system-estimation-technique-index-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-the-gamification-of-effort-estimation-with-planning-poker-index-mdx": () => import("./../../../src/templates/blog-post-template.tsx?__contentFilePath=/opt/buildhome/repo/src/content/posts/the-gamification-of-effort-estimation-with-planning-poker/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-the-gamification-of-effort-estimation-with-planning-poker-index-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-the-role-of-measuring-value-in-measuring-success-index-mdx": () => import("./../../../src/templates/blog-post-template.tsx?__contentFilePath=/opt/buildhome/repo/src/content/posts/the-role-of-measuring-value-in-measuring-success/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-the-role-of-measuring-value-in-measuring-success-index-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-top-reasons-why-teams-have-inaccurate-backlog-estimates-index-mdx": () => import("./../../../src/templates/blog-post-template.tsx?__contentFilePath=/opt/buildhome/repo/src/content/posts/top-reasons-why-teams-have-inaccurate-backlog-estimates/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-top-reasons-why-teams-have-inaccurate-backlog-estimates-index-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-top-security-tips-for-programmers-working-remotely-index-mdx": () => import("./../../../src/templates/blog-post-template.tsx?__contentFilePath=/opt/buildhome/repo/src/content/posts/top-security-tips-for-programmers-working-remotely/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-top-security-tips-for-programmers-working-remotely-index-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-ukraine-needs-your-help-index-mdx": () => import("./../../../src/templates/blog-post-template.tsx?__contentFilePath=/opt/buildhome/repo/src/content/posts/ukraine-needs-your-help/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-ukraine-needs-your-help-index-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-velocity-is-a-forecasting-tool-not-a-success-metric-in-scrum-index-mdx": () => import("./../../../src/templates/blog-post-template.tsx?__contentFilePath=/opt/buildhome/repo/src/content/posts/velocity-is-a-forecasting-tool-not-a-success-metric-in-scrum/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-velocity-is-a-forecasting-tool-not-a-success-metric-in-scrum-index-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-what-can-a-team-miss-without-estimating-the-product-backlog-items-index-mdx": () => import("./../../../src/templates/blog-post-template.tsx?__contentFilePath=/opt/buildhome/repo/src/content/posts/what-can-a-team-miss-without-estimating-the-product-backlog-items/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-what-can-a-team-miss-without-estimating-the-product-backlog-items-index-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-what-can-go-wrong-without-estimating-in-agile-planning-index-mdx": () => import("./../../../src/templates/blog-post-template.tsx?__contentFilePath=/opt/buildhome/repo/src/content/posts/what-can-go-wrong-without-estimating-in-agile-planning/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-what-can-go-wrong-without-estimating-in-agile-planning-index-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-what-is-not-the-responsibility-of-scrum-master-index-mdx": () => import("./../../../src/templates/blog-post-template.tsx?__contentFilePath=/opt/buildhome/repo/src/content/posts/what-is-not-the-responsibility-of-scrum-master/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-what-is-not-the-responsibility-of-scrum-master-index-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-what-is-product-owner-index-mdx": () => import("./../../../src/templates/blog-post-template.tsx?__contentFilePath=/opt/buildhome/repo/src/content/posts/what-is-product-owner/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-what-is-product-owner-index-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-what-is-scrum-master-index-mdx": () => import("./../../../src/templates/blog-post-template.tsx?__contentFilePath=/opt/buildhome/repo/src/content/posts/what-is-scrum-master/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-what-is-scrum-master-index-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-what-is-t-shirt-sizing-index-mdx": () => import("./../../../src/templates/blog-post-template.tsx?__contentFilePath=/opt/buildhome/repo/src/content/posts/what-is-t-shirt-sizing/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-what-is-t-shirt-sizing-index-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-what-is-the-expected-output-of-a-planning-poker-meeting-index-mdx": () => import("./../../../src/templates/blog-post-template.tsx?__contentFilePath=/opt/buildhome/repo/src/content/posts/what-is-the-expected-output-of-a-planning-poker-meeting/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-what-is-the-expected-output-of-a-planning-poker-meeting-index-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-what-makes-planning-poker-the-best-estimation-technique-index-mdx": () => import("./../../../src/templates/blog-post-template.tsx?__contentFilePath=/opt/buildhome/repo/src/content/posts/what-makes-planning-poker-the-best-estimation-technique/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-what-makes-planning-poker-the-best-estimation-technique-index-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-why-agile-teams-should-do-software-estimates-index-mdx": () => import("./../../../src/templates/blog-post-template.tsx?__contentFilePath=/opt/buildhome/repo/src/content/posts/why-agile-teams-should-do-software-estimates/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-why-agile-teams-should-do-software-estimates-index-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-why-does-planning-poker-use-story-points-instead-of-time-values-index-mdx": () => import("./../../../src/templates/blog-post-template.tsx?__contentFilePath=/opt/buildhome/repo/src/content/posts/why-does-planning-poker-use-story-points-instead-of-time-values/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-why-does-planning-poker-use-story-points-instead-of-time-values-index-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-why-estimate-with-story-points-index-mdx": () => import("./../../../src/templates/blog-post-template.tsx?__contentFilePath=/opt/buildhome/repo/src/content/posts/why-estimate-with-story-points/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-why-estimate-with-story-points-index-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-why-is-scrum-so-popular-index-mdx": () => import("./../../../src/templates/blog-post-template.tsx?__contentFilePath=/opt/buildhome/repo/src/content/posts/why-is-scrum-so-popular/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-why-is-scrum-so-popular-index-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-why-is-the-modified-fibonacci-sequence-used-when-estimating-index-mdx": () => import("./../../../src/templates/blog-post-template.tsx?__contentFilePath=/opt/buildhome/repo/src/content/posts/why-is-the-modified-fibonacci-sequence-used-when-estimating/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-why-is-the-modified-fibonacci-sequence-used-when-estimating-index-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-why-it-is-essential-to-estimate-product-backlog-index-mdx": () => import("./../../../src/templates/blog-post-template.tsx?__contentFilePath=/opt/buildhome/repo/src/content/posts/why-it-is-essential-to-estimate-product-backlog/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-why-it-is-essential-to-estimate-product-backlog-index-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-why-scrum-transformation-fails-for-many-organizations-index-mdx": () => import("./../../../src/templates/blog-post-template.tsx?__contentFilePath=/opt/buildhome/repo/src/content/posts/why-scrum-transformation-fails-for-many-organizations/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-why-scrum-transformation-fails-for-many-organizations-index-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-why-should-you-use-async-poker-to-estimate-a-product-backlog-index-mdx": () => import("./../../../src/templates/blog-post-template.tsx?__contentFilePath=/opt/buildhome/repo/src/content/posts/why-should-you-use-async-poker-to-estimate-a-product-backlog/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-why-should-you-use-async-poker-to-estimate-a-product-backlog-index-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-why-the-whole-team-should-participate-in-planning-poker-estimation-index-mdx": () => import("./../../../src/templates/blog-post-template.tsx?__contentFilePath=/opt/buildhome/repo/src/content/posts/why-the-whole-team-should-participate-in-planning-poker-estimation/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-why-the-whole-team-should-participate-in-planning-poker-estimation-index-mdx" */),
  "component---src-templates-mdx-with-table-of-contents-tsx-content-file-path-src-content-monday-async-poker-mdx": () => import("./../../../src/templates/mdx-with-table-of-contents.tsx?__contentFilePath=/opt/buildhome/repo/src/content/monday/async-poker.mdx" /* webpackChunkName: "component---src-templates-mdx-with-table-of-contents-tsx-content-file-path-src-content-monday-async-poker-mdx" */),
  "component---src-templates-mdx-with-table-of-contents-tsx-content-file-path-src-content-monday-sync-poker-mdx": () => import("./../../../src/templates/mdx-with-table-of-contents.tsx?__contentFilePath=/opt/buildhome/repo/src/content/monday/sync-poker.mdx" /* webpackChunkName: "component---src-templates-mdx-with-table-of-contents-tsx-content-file-path-src-content-monday-sync-poker-mdx" */),
  "component---src-templates-solution-doc-template-tsx-content-file-path-src-content-monday-async-poker-mdx": () => import("./../../../src/templates/solution-doc-template.tsx?__contentFilePath=/opt/buildhome/repo/src/content/monday/async-poker.mdx" /* webpackChunkName: "component---src-templates-solution-doc-template-tsx-content-file-path-src-content-monday-async-poker-mdx" */),
  "component---src-templates-solution-doc-template-tsx-content-file-path-src-content-monday-sync-poker-mdx": () => import("./../../../src/templates/solution-doc-template.tsx?__contentFilePath=/opt/buildhome/repo/src/content/monday/sync-poker.mdx" /* webpackChunkName: "component---src-templates-solution-doc-template-tsx-content-file-path-src-content-monday-sync-poker-mdx" */)
}

